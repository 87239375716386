// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    processing: false,
    typing: "msg-00000-0000-000",
    lastMessage: false,
    query: "",
    persona: "",
    messages: [
      {
        id: "msg-00000-0000-000",
        type: "welcome",
        role: "assistant",
        data: {
          display: true
        },
        content: "Hello! I'm Persona AI, your customer co-pilot. I can help answer specific questions about your customers and their behavior. How may I help you today?"
      }
    ]
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const agentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_AGENT: {
          console.log("Initializing agent...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        case actionTypes.CLEAR_MESSAGES: {

          // Update state
          return {
              ...state,
              processing: false,
              typing: "msg-00000-0000-000",
              messages: [
                {
                  id: "msg-00000-0000-000",
                  type: "welcome",
                  role: "assistant",
                  data: {
                    display: true
                  },
                  content: (action.payload && action.payload.content) ? action.payload.content : "Hello! I'm Persona AI, your customer co-pilot. I can help answer specific questions about your customers and their behavior. How may I help you today?"
                }
              ]
          };
        }
        case actionTypes.ADD_CHAT_MESSAGE: {
          // Get messages
          let messages = state.messages;

          // Add new message to queue
          messages.push({
            id: action.payload.id,
            type: action.payload.type,
            role: action.payload.role,
            data: action.payload.data,
            content: action.payload.content
          });

          // Update state
          return {
              ...state,
              messages: messages
          };
        }
        case actionTypes.SET_PROCESSING: {
          // Update state
          return {
              ...state,
              processing: action.payload.status
          };
        }
        case actionTypes.SET_QUERY: {
          // Update state
          return {
              ...state,
              query: action.payload.query
          };
        }
        case actionTypes.SET_PERSONA: {
          console.log("Setting Chat Persona: ", action.payload);

          // Update state
          return {
              ...state,
              persona: action.payload.persona
          };
        }
        default:
            return state;
    }
};

export default agentReducer;

/*

,
{
  id: 'msg-00000-0001-000',
  type: 'query',
  role: 'user',
  data: {
    display: true
  },
  content: "Hi, can you summarize last week's marketing performance?"
},
{
  id: 'msg-00000-0002-000',
  type: 'response',
  role: 'assistant',
  data: {
    display: true
  },
  content: 'I am an AI language model, and I do not have access to real-time data or the marketing performance of any particular company or brand. However, I can help answer questions or provide guidance on marketing strategies and tactics if you would like.'
},
{
  id: 'msg-00000-0003-000',
  type: 'query',
  role: 'user',
  data: {
    display: true
  },
  content: 'yeah, how would you appraoch reaching this customer segment?'
},
{
  id: 'msg-00000-0004-000',
  type: 'response',
  role: 'assistant',
  data: {
    display: true
  },
  content: 'To effectively reach this customer segment, a business should consider the following marketing strategies:'
},
{
  id: 'msg-00000-0004-001',
  type: 'thread',
  role: 'assistant',
  data: {
    display: true
  },
  content: '1. Defining the target audience: Understand the key characteristics of your desired customer segment: professionals who value quality materials, stylish and versatile clothing, and are willing to invest in long-lasting items.'
},
{
  id: 'msg-00000-0004-002',
  type: 'thread',
  role: 'assistant',
  data: {
    display: true
  },
  content: '2. Content marketing: Develop high-quality, engaging, and informative content that showcases the stylish and versatile aspects of your products. Include fashion tips, style guides, and other useful information that the target audience will appreciate. Publish this content on various channels, such as a blog, social media, and email newsletters.'
},
{
  id: 'msg-00000-0004-003',
  type: 'response',
  role: 'assistant',
  data: {
    display: true
  },
  content: 'By implementing these marketing strategies, you can effectively reach this customer segment and create a message that resonates with their lifestyle choices and preferences.'
}

*/
